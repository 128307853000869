@import-normalize;

@font-face {
    font-family: "Basier Circle";
    src: url("./assets/fonts/basiercircle-regular-webfont.eot");
    src: url("./assets/fonts/basiercircle-regular-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/basiercircle-regular-webfont.woff2") format("woff2"),
        url("./assets/fonts/basiercircle-regular-webfont.woff") format("woff"),
        url("./assets/fonts/basiercircle-regular-webfont.ttf")
            format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Basier Circle Medium";
    src: url("./assets/fonts/basiercircle-medium-webfont.eot");
    src: url("./assets/fonts/basiercircle-medium-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/basiercircle-medium-webfont.woff2") format("woff2"),
        url("./assets/fonts/basiercircle-medium-webfont.woff") format("woff"),
        url("./assets/fonts/basiercircle-medium-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

body,
.apply-font-main,
.apply-font-heading {
    font-family: "Basier Circle", sans-serif;
}

.App {
    display: flex;
    min-height: 100vh;
}

.App > main {
    display: flex;
    flex-direction: column;
    flex: 0 0 375px;
    max-width: 375px;
    z-index: 1024;
}

.App > aside {
    flex: 1 0 auto;
    max-height: 100vh;
    position: fixed;
    width: calc(100% - 375px);
    right: 0;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    z-index: 1000;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.App > aside::-webkit-scrollbar {
    display: none;
}

.App .smallScreenWarning {
    display: none;
}

@media screen and (max-width: 400px) {
    .App .smallScreenWarning {
        display: block;
    }
}
